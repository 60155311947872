<template>
  <div class="row progress-wizard" style="border-bottom:0;">
    <div
      v-for="item in levelBarData"
      :key="item.level"
      :class="item.status"
      @click="item.status === 'incomplete' ? $emit('rejectPlay') : $emit('handlePlay', item.level)"
      class="col-sm col-xs-12 progress-wizard-step"
    >
      <div class="progress">
        <div class="progress-bar"></div>
      </div>
      <a href="javascript:void(0)" class="progress-wizard-dot">
        <i class="fa" aria-hidden="true">Lv. {{ item.level }}</i>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FQLevelBar',
  props: {
    userInfo: {
      type: [Object, Array],
      required: true
    },
    currentGrade: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      levelBarData: [
        {
          level: 1,
          status: 'active'
        },
        {
          level: 2,
          status: 'incomplete'
        },
        {
          level: 3,
          status: 'incomplete'
        },
        {
          level: 4,
          status: 'incomplete'
        },
        {
          level: 5,
          status: 'incomplete'
        }
      ]
    }
  },
  computed: {
    grade () {
      return this.$route.params.grade
    },
    level () {
      return this.$route.params.level
    }
  },
  watch: {
    userInfo () {
      this.setLevelBar()
    },
    grade () {
      this.setLevelBar()
    }
  },
  methods: {
    setLevelBar () {
      if (Array.isArray(this.userInfo)) return null
      console.log('setLevelBar')
      // 如果使用者等級大於這個遊戲的等級
      if (this.userInfo.grade > this.currentGrade) {
        // 把 levelbar 全部填滿
        this.levelBarData.forEach(item => { item.status = 'complete' })
        return null
      }
      this.levelBarData.forEach(item => {
        // 拿使用者等級去跟等級圈圈的等級比
        // 如果使用者等級比該圈圈等級大
        // 就掛上 complete 的 html class
        if (this.userInfo.level > item.level) {
          item.status = 'complete'
          return null
        }
        // 使用者等級等於目前等級，掛上 active 的 html class
        if (this.userInfo.level === item.level) {
          item.status = 'active'
          return null
        }
        item.status = 'incomplete'
      })
    }
  }
}
</script>
