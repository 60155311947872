<template>
  <div class="main-wrapper events">
    <!-- ====================================
    ———BREADCRUMB
    ===================================== -->
    <section class="py-9 bg-parallax" style="background-image: url(/assets/img/background/background-img-1.jpg);">
      <div class="container">
        <div class="breadcrumb-holder wow fadeInUp">
          <div>
            <h1 class="breadcrumb-title">FQ大冒險</h1>
            <p class="text-white font-size-16">大家聽說IQ、EQ，比較少聽過FQ(財商)，IQ高代表你很聰明，EQ高代表你情緒管理好，FQ則是代表 你管理金錢的能力好。 財商高也代表你的金融素養好，懂得金錢的價值，擁有正確花錢態度，更知 道如何理財讓錢長大，現在就開始進行你的 FQ （財商 ) 小測驗～</p>
            <ul class="breadcrumb breadcrumb-transparent">
              <li class="breadcrumb-item ">
                <a class="text-white" href="/">Home</a>
              </li>
              <li class="breadcrumb-item text-white active" aria-current="page">FQ大冒險</li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <!-- ====================================
    ———EVENTS SECTION
    ===================================== -->
    <section>
      <div class="container py-8">
        <div class="section-title justify-content-center mb-6 mb-md-10">
          <span class="shape shape-left bg-info"></span>
          <h2 class="text-danger">{{ title }}</h2>
          <span class="shape shape-right bg-info"></span>
        </div>
        <FQLevelBar
          v-if="currentGrade > 0"
          :userInfo="userInfo"
          :currentGrade="currentGrade"
          ref="FQLevelBar"
          @rejectPlay="onRejectPlay"
          @handlePlay="onHandlePlay"
        />
        <button v-else-if="!showContinuePlay" @click="onHandlePlay(currentGrade)" class="btn btn-danger d-block mx-auto">開始玩</button>
        <!-- 108課綱 -->
        <button v-if="currentGrade < 0 && showContinuePlay" @click="continuePlay(-1)" class="btn btn-danger d-block mx-auto">繼續玩</button>
        <!-- 一般遊戲 -->
        <button v-else-if="showContinuePlay" @click="continuePlay(userInfo.level)" class="btn btn-danger d-block mx-auto">繼續玩</button>
        <div class="game-section__anchor"></div>
        <FQGameSection v-if="$route.query.level && showGameSection" :gameId="gameId" :userId="userInfo.id" @gameLoaded="scrollToGame" />
      </div>
    </section>
  </div> <!-- element wrapper ends -->
</template>

<script>
import FQLevelBar from '@/components/FQLevelBar.vue'
import FQGameSection from '@/components/FQGameSection.vue'
import fqgameConfig from '@/views/fqgame/fqgame.config.json'
import tokenGenerator from '@/tokenGenerator.js'
export default {
  name: 'FQGameBoard',
  components: { FQLevelBar, FQGameSection },
  data () {
    return {
      title: '',
      link: '',
      nextLevelLink: '',
      currentGrade: 1,
      userInfo: {
        level: 1,
        grade: 1,
        id: 0
      },
      gameId: '',
      isPlaying: false,
      showContinuePlay: false,
      verticalLocation: 0,
      showGameSection: true
    }
  },
  computed: {
    grade () {
      return this.$route.params.grade
    }
  },
  watch: {
    grade () {
      const vm = this
      const fqgameData = fqgameConfig[this.grade]

      if (!fqgameData) {
        vm.$router.push('/fqgame')
        return null
      }
      vm.currentGrade = fqgameData.currentGrade
      vm.title = fqgameData.title
      vm.link = fqgameData.link
      vm.nextLevelLink = fqgameData.nextLink
    }
  },
  created () {
    const memberToken = localStorage.getItem('memberToken')
    const vm = this
    const api = `${process.env.VUE_APP_APIPATH}/iPlay/member/check`

    if (!memberToken) {
      alert('請先登入以進行遊戲')
      location.href = '/login'
      return null
    }

    const fqgameData = fqgameConfig[this.grade]

    if (!fqgameData) {
      vm.$router.push('/fqgame')
      return null
    }
    vm.currentGrade = fqgameData.currentGrade
    vm.title = fqgameData.title
    vm.link = fqgameData.link
    vm.nextLevelLink = fqgameData.nextLink

    vm.axios.post(api, {
      memberToken,
      token: tokenGenerator()
    }).then(res => {
      if (Array.isArray(res.data.content)) {
        alert('請先登入以進行遊戲')
        vm.$router.push('/login')
        return null
      }
      vm.userInfo = res.data.content

      if (vm.userInfo.grade < vm.currentGrade) {
        this.$router.push('/fqgame')
        return null
      }
    })
  },
  mounted () {
    require('@/assets/js/base.js')
    const vm = this
    const api = `${process.env.VUE_APP_APIPATH}/iPlay/member/check`
    const memberToken = localStorage.getItem('memberToken')
    // 精誠會postMessage到前端
    // 前端會用eventListener來接
    window.addEventListener('message', (e) => {
      if (e.origin === 'https://popquiz.megatime.com.tw') {
        vm.isPlaying = false
        vm.axios.post(api, {
          memberToken,
          token: tokenGenerator()
        }).then((res) => {
          const userInfo = res.data.content
          if (Array.isArray(userInfo)) {
            // 如果遊戲玩到一半token到期了會請他重新登入（但遊戲資料一樣會被儲存在後端）
            alert('請重新登入')
            this.$router.push('/login')
            return null
          }
          // 檢查使用者是否升級或晉級了
          // userInfo是在上面串api拿到的資料
          if (vm.currentGrade === vm.userInfo.grade && userInfo.grade > vm.userInfo.grade) {
            alert('恭喜你升級了！')
            location.href = `/fqgame/${vm.nextLevelLink}?isContinuePlay=1`
          } else if (vm.currentGrade === vm.userInfo.grade && userInfo.level > vm.userInfo.level) {
            alert('恭喜你升級了！')
            location.href = `/fqgame/${vm.link}?isContinuePlay=1`
          } else {
            vm.showContinuePlay = true
            vm.scrollToGame()
          }
        })
      }
    })
    setTimeout(() => {
      if (this.$route.query.isContinuePlay === '1') {
        this.onHandlePlay(vm.userInfo.level)
      }
    }, 1000)
  },
  methods: {
    onRejectPlay () {
      alert('等級不符，請先玩其他等級的遊戲喔')
    },
    onHandlePlay (level) {
      const vm = this
      const api = `${process.env.VUE_APP_APIPATH}/iPlay/question/getCode`
      if (vm.isPlaying) {
        alert('已有進行中的遊戲')
        return null
      }

      alert('開始玩')
      vm.showContinuePlay = false
      vm.isPlaying = true
      // 串接後端api
      // 把遊戲題組帶進FQGameSection
      // 改變網址讓 v-if 觸發 FQGameSection出現
      // FQGameSection 拿到 gameId 就可以串接到精誠的遊戲
      vm.axios.post(api, {
        memberId: vm.userInfo.id,
        grade: vm.currentGrade,
        level,
        token: tokenGenerator()
      }).then(res => {
        if (res.data.status === '000' && res.data.errmsg === '') {
          vm.gameId = res.data.content
          // vm.$router.push(`/fqgame/${vm.link}?level=${level}`)
          vm.$router.replace({ query: { level } })
        } else if (res.data.status === '004' && res.data.errmsg === '已無可用題組') {
          alert('很抱歉，目前無法遊玩此題組')
        }
      })
    },
    continuePlay (level) {
      const vm = this
      const api = `${process.env.VUE_APP_APIPATH}/iPlay/question/getCode`
      vm.showContinuePlay = false
      vm.isPlaying = true
      // 串接API取得遊戲題組
      vm.axios.post(api, {
        memberId: vm.userInfo.id,
        grade: vm.currentGrade,
        level,
        token: tokenGenerator()
      }).then(res => {
        if (res.data.status === '000' && res.data.errmsg === '') {
          if (vm.gameId !== res.data.content) {
            // 把遊戲題組丟給FQGameSection
            vm.gameId = res.data.content
          } else {
            // 處理遊戲題組和上一次遊玩的遊戲題組一模一樣時特別處理
            vm.gameId = ''
            vm.showGameSection = false
            vm.$nextTick(() => {
              vm.gameId = res.data.content
              vm.showGameSection = true
              setTimeout(() => {
                vm.scrollToGame()
              }, 1500)
            })
          }
        } else if (res.data.status === '004' && res.data.errmsg === '已無可用題組') {
          alert('很抱歉，目前無法遊玩此題組')
        }
      })
    },
    scrollToGame () {
      const loc = document.querySelector('.game-section__anchor').getBoundingClientRect().bottom
      window.scrollTo(0, loc)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/kidz.css";
</style>
