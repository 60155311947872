<template>
  <div id="fq-game-section" class="game-section__wrapper">
    <iframe :src="iframePath" ref="gameSection" frameborder="0" class="game-section__iframe"></iframe>
  </div>
</template>

<script>
export default {
  name: 'FQGameSection',
  props: {
    gameId: {
      type: String,
      required: true
    },
    userId: {
      type: Number,
      required: true
    }
  },
  computed: {
    iframePath () {
      return `https://popquiz.megatime.com.tw/pop-quiz-offline/${this.gameId}/welcome?userId=${this.userId}&env=${this.FQGameEnv}`
    },
    FQGameEnv () {
      return process.env.VUE_APP_FQGAME_ENV || 2
    }
  },
  created () {
    if (this.userId === 0) {
      this.$router.push('/fqgame/kindergarten/')
    }
    const level = +this.$route.query.level
    if (Number.isNaN(level) || level > 5) {
      this.$router.push('/fqgame/kindergarten/')
      return null
    }
  },
  mounted () {
    // 遊戲載入時捲動至遊戲
    const gameIFrame = document.querySelector('.game-section__iframe')
    const navbar = document.querySelector('nav')
    const location = gameIFrame.getBoundingClientRect().top - navbar.getBoundingClientRect().height
    gameIFrame.onload = (e) => {
      if (e.target.src.includes('https://popquiz.megatime.com.tw')) {
        this.$emit('gameLoaded', location)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.game-section {
  &__wrapper {
    height: 600px;
  }
  &__iframe {
    display: block;
    width: 100%;
    height: 100%;
  }
}
</style>
